import * as React from "react";
import axios from "axios";
import { useRouter } from 'next/router'
import clsx from "clsx";
import { useForm } from "react-hook-form";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import LoadingButton from "@mui/lab/LoadingButton";
import Link from '@/theme/Link';
import IconFlaticon from '@/components/IconFlaticon';
import { StyledFormControl } from "@/components/showcase/_controls";

export default function Login({ shop, userSSR }){
    const { push, asPath, pathname, query } = useRouter();
    const { control, handleSubmit, setError, clearErrors, register, reset, formState: { errors } } = useForm();
    const [loading, setLoading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    // const { user, mutateUser } = useUser({
    //     redirectTo: "/", // сюда редиректим после авторизации
    //     redirectIfFound: true
    // })


    const onError = (errors, e) => {
        // console.log(errors, e);
        clearErrors()
    }

    async function onSubmit(data){
        let validEmail = data.email && /.+@.+\.[A-Za-z]+$/.test(data.email);
        setLoading(true)

        if(!validEmail){
            setError('email', { type: 'custom', message: 'E-mail не указан!' })
        }

        if(!data.password){
            setError('password', { type: 'custom', message: 'Пароль не указан!' })
        }

        if(validEmail && data.password){
            const response = await axios
                .post('/api/login', {
                    identifier: data.email.trim(),
                    password: data.password.trim(),
                    shopPrefix: shop?.shopPrefix.trim(),
                })

            if(response.data.id){
                if(response.data.redirect){
                    await push(`https://${response.data.shopPrefix}.${process.env.NEXT_PUBLIC_DEFAULT_URL}/login?token=${response.data.token}&userId=${response.data.id}`)
                }else{
                    await push(asPath)
                }
            }
            if(response.data.error){
                if(response.data.error.length > 0 ){
                    response.data.error.forEach((e) => {
                        e.path.forEach((paht) => {
                        //console.log('=paht=', paht);
                            setError(paht, { type: 'custom', message: e.message })
                        })
                    })
                }
            }
        }
        setLoading(false);
    }

    return(
        <div className='flex flex-col w-full tablet:w-96 laptop:w-130 px-3 tablet:px-0 mt-0 laptop:mt-16 pb-12'>
            <div className='pb-12 text-center text-xl tablet:text-3xl laptop:text-40px font-black'>Авторизация</div>
            <form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
                <StyledFormControl fullWidth>
                    <div className='mb-2 text-sm tablet:text-base'>
                        Логин (E-mail)
                    </div>
                    <OutlinedInput
                        {...register("email")}
                        error={!!errors.email}
                        fullWidth
                        name="email"
                        id="email"
                        autoComplete="email"
                        className="text-base"
                    />
                    <div className={clsx('mt-1 mb-6 text-sm', errors.email ? 'text-red-1' : 'text-gray-9')}>
                        {errors.email && ( `${errors.email.message}` )}
                    </div>
                </StyledFormControl>

                <StyledFormControl fullWidth>
                    <div className='mb-2 text-sm tablet:text-base'>
                        Пароль
                    </div>
                    <OutlinedInput
                        {...register("password")}
                        error={!!errors.password}
                        fullWidth
                        name="password"
                        id="password"
                        autoComplete="password"
                        className="text-base"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    className={'bg-transparent hover:bg-transparent'}
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                {showPassword ? <IconFlaticon className='h-6 text-black' name='rr-eye-crossed' /> : <IconFlaticon className='h-6 text-gray-8' name='rr-eye' />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <div className={clsx('mt-1 mb-6 text-sm', errors.password || errors.system ? 'text-red-1' : 'text-gray-9')}>
                        {errors.password && ( `${errors.password.message}` )}
                        {errors.system && ( `${errors.system.message}` )}
                    </div>
                </StyledFormControl>
                {/*<FormHelperText error={!!errors.system}>*/}
                {/*    {errors.system && ( `${errors.system.message}` )}*/}
                {/*</FormHelperText>*/}
                <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    className='mt-4 py-3.5 laptop:py-3 bg-green2 hover:bg-green3 text-sm laptop:text-base font-normal text-white bg-gradient-to-r from-pink-1 to-purple-1 hover:brightness-90 rounded-12 shadow-none hover:shadow-none normal-case'
                >
                    Войти
                </LoadingButton>
            </form>
            <div className='mt-10 text-center text-sm tablet:text-base'>
                Забыли пароль?<Link href="/forgot" noLinkStyle className='ml-2 text-purple-1'>Восстановить</Link>
            </div>
        </div>
    )
}