import * as React from "react";
import Head from 'next/head'
import LoginFormMui from '@/components/ecommy/shop/user/LoginFormMui'
import Header from "@/components/showcase/Header";
import LoginShow from "@/components/showcase/Login";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getRedirect, getShop, getUserSSR } from '../../lib/fetchJson';
import { withIronSessionSsr } from "iron-session/next";
import { sessionOptions } from '../../lib/session';

function Login({shop, userSSR }) {
    // if(!shop){
    //     return <></>
    // }
    const screenPhone = useMediaQuery('(max-width:470px)');
    return (
        <>
            <Head>
                <title>Авторизация</title>
            </Head>
            <Header type={'compact'} showBack={screenPhone} showDemo={false} showCart={false} showSettings={true}/>
            <LoginShow shop={shop} userSSR={userSSR} />
        </>
    )
}
  
export default Login


export const getServerSideProps = withIronSessionSsr(
    async function getServerSideProps(ctx) {
        const { req, query } = ctx
        let userSSR = { isLoggedIn: false, login: "", avatarUrl: "" }
        if(req.session.user){
            userSSR = await getUserSSR(req)
        }
        const redirect = getRedirect("/", true, userSSR)
        if(redirect){
            return redirect
        }
        return { props: { userSSR } }
    },
    sessionOptions
)