import * as React from 'react';
import axios from 'axios';
import { useForm  } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@/theme/Link';
import useUser from '../../../../../lib/useUser';
import { GreenFormControl} from '@/components/ecommy/shop/MaterialShop';
import IconFlaticon from '@/components/IconFlaticon';


export default function LoginFormMui() {
  const { control, register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { user, mutateUser } = useUser({
    redirectTo: "/",
    redirectIfFound: true,
  })

  const onError = (errors, e) => {
    // console.log(errors, e);
    clearErrors()
  }


  async function onSubmit(data){
    setLoading(true)
    const response = await axios
      .post('/api/login', {
        identifier: data.email,
        password: data.password,
      })
    if(response.data.id){
      mutateUser(
        response.data,
        false,
      )
    }
    if(response.data.error){
      if(response.data.error.length > 0 ){
        response.data.error.forEach((e) => {
          e.path.forEach((paht) => {
            //console.log('=paht=', paht);
            setError(paht, { type: 'custom', message: e.message })
          })
        })
      }
    }
    setLoading(false)
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
      <div className='w-full flex flex-row items justify-center'>
        <div className='w-full max-w-sm flex flex-col items-center space-y-2 laptop:space-y-4 pt-9 px-5 laptop:px-0'>
          <img className='w-14 laptop:w-auto' src='/assets/di_tek/auth/402.svg' alt='Авторизация' />
          <div className='text-2xl laptop:text-20 font-bold leading-none'>Авторизация</div>
          <div className='w-full max-w-sm flex flex-col space-y-4 laptop:space-y-6 pt-6'>
            <GreenFormControl fullWidth className=''>
              <OutlinedInput
                {...register("email")}
                error={!!errors.email}
                fullWidth
                placeholder='Логин (E-mail)'
                name="email"
                id="email"
                className="text-sm laptop:text-base"
                autoComplete="email"
              />
              <FormHelperText error={!!errors.email}>
                {errors.email && ( `${errors.email.message}` )}
              </FormHelperText>
            </GreenFormControl>
            <GreenFormControl>
              <OutlinedInput
                id="password"
                className="text-sm laptop:text-base"
                autoComplete="new-password"
                {...register("password")}
                error={!!errors.password}
                fullWidth
                placeholder='Пароль'
                name="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <IconFlaticon className='text-gray19' name='rr-eye-crossed' /> : <IconFlaticon  className='text-gray19' name='rr-eye' />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error={!!errors.password}>
                {errors.password && ( `${errors.password.message}` )}
              </FormHelperText>
            </GreenFormControl>
          </div>
          {/*<FormControlLabel*/}
          {/*  control={<Checkbox */}
          {/*    icon={<IconFlaticon className='text-gray20 text-xl' name='rr-square' />}*/}
          {/*    checkedIcon={<IconFlaticon className='text-green2 text-xl' name='sr-checkbox' />}*/}
          {/*    value="remember" color="primary" />}*/}
          {/*  label="Запомнить меня"*/}
          {/*/>*/}
          <FormHelperText error={!!errors.system}>
                {errors.system && ( `${errors.system.message}` )}
          </FormHelperText>
          <div className='w-full pt-2'>
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              className='py-3.5 laptop:py-3 bg-green2 hover:bg-green3 text-sm laptop:text-base font-normal text-black rounded-16 shadow-none hover:shadow-none normal-case'
            >
              Войти
            </LoadingButton>
          </div>
          <div className="flex flex-col items-center pt-6 laptop:pt-7 space-y-5 laptop:space-y-7 text-sm laptop:text-base">
            <Link href="/forgot" noLinkStyle className='text-green12 hover:text-green9'>
                Забыли пароль?
            </Link>
            <div className='flex flex-row space-x-2'>
              <span>Нет аккаунта?</span>
              <Link noLinkStyle className='text-green12 hover:text-green9' href="/signup" >
                Зарегистрироваться
              </Link>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}